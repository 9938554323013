import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useKonamiCode } from '@silae/composables';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { defaultLayoutComponents } from '../layout/layout';
import { LeaveDaysRequestsRoute } from '../leave-days/leave-days.route.ts';

export const RemoteWorkRoute: RouteRecordRaw = {
	name: RouteKeys.REMOTE_WORK_REQUESTS,
	path: '/requests/remote-work',
	component: () => import('../mobilities/remote-work.vue'),
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			const { konami } = useKonamiCode();
			return computed(() => isAdmin.value || konami.value);
		}
	}
};

export const RequestsRoute: RouteRecordRaw = {
	name: RouteKeys.REQUESTS,
	path: '/requests',
	components: {
		default: () => import('./requests.vue'),
		...defaultLayoutComponents
	},
	children: [LeaveDaysRequestsRoute, RemoteWorkRoute],
	redirect: () => LeaveDaysRequestsRoute,
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			const { konami } = useKonamiCode();
			return computed(() => isAdmin.value || !konami.value);
		},
		label: 'requests.navigation',
		icon: '$list',
		isNavEntry: true,
		order: 3,
		requiresAuth: true
	}
};
