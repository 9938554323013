import { LeaveDaysTypeDTO, LeaveDaysTypeFamily } from '~/api';

export type LeaveDaysType = LeaveDaysTypeDTO & LeaveDaysTypeFamilyDetails;

export type LeaveDaysTypeFamilyDetails = {
	color: string;
	displayInLegend?: boolean;
	familyLabel: string;
	icon: string;
};

export const UNKNOWN_FAMILY: LeaveDaysTypeFamilyDetails = {
	color: 'secondary',
	familyLabel: 'leave_days.families.unknown',
	icon: '$help'
};

export const RESTRICTED_ACCESS_FAMILY: LeaveDaysTypeFamilyDetails = {
	color: 'secondary',
	familyLabel: 'leave_days.families.restricted_access',
	icon: '$hide'
};

export const LEAVE_DAYS_TYPES_FAMILIES: Record<LeaveDaysTypeFamily, LeaveDaysTypeFamilyDetails> = {
	[LeaveDaysTypeFamily.ABSENCE]: {
		color: 'secondary',
		familyLabel: 'leave_days.families.absence',
		icon: '$help'
	},
	[LeaveDaysTypeFamily.LEGAL]: {
		color: 'terracotta',
		displayInLegend: true,
		familyLabel: 'leave_days.families.legal',
		icon: 'book'
	},
	[LeaveDaysTypeFamily.OTHER]: {
		color: 'emerald',
		displayInLegend: true,
		familyLabel: 'leave_days.families.other',
		icon: 'folder'
	},
	[LeaveDaysTypeFamily.UNEMPLOYMENT]: {
		color: 'mustard',
		displayInLegend: true,
		familyLabel: 'leave_days.families.unemployment',
		icon: 'society-cross'
	},
	[LeaveDaysTypeFamily.BUSINESS_TRAVEL]: {
		color: 'caramel',
		familyLabel: 'leave_days.families.business_travel',
		icon: 'baggage'
	},
	[LeaveDaysTypeFamily.FAMILY]: {
		color: 'purple',
		displayInLegend: true,
		familyLabel: 'leave_days.families.family',
		icon: 'user-family'
	},
	[LeaveDaysTypeFamily.TRAINING]: {
		color: 'apple',
		displayInLegend: true,
		familyLabel: 'leave_days.families.formation',
		icon: 'mortarboard'
	},
	[LeaveDaysTypeFamily.REST]: {
		color: 'cumulus',
		displayInLegend: true,
		familyLabel: 'leave_days.families.rest',
		icon: 'parasol'
	},
	[LeaveDaysTypeFamily.HEALTH]: {
		color: 'pink',
		displayInLegend: true,
		familyLabel: 'leave_days.families.health',
		icon: 'health'
	},
	[LeaveDaysTypeFamily.REMOTEWORK]: {
		color: 'secondary',
		familyLabel: 'leave_days.families.teleworking',
		icon: 'user-house'
	}
};

export function asLeaveDayType<T extends LeaveDaysTypeDTO>(dto: T): T & LeaveDaysTypeFamilyDetails {
	return { ...dto, ...(LEAVE_DAYS_TYPES_FAMILIES?.[dto?.groupLabel] ?? UNKNOWN_FAMILY) };
}
