import { useI18n } from 'vue-i18n';

import {
	Message,
	notifyDialogError,
	notifyDialogInfo,
	notifyDialogSuccess,
	notifyDialogWarning,
	notifyToastError,
	notifyToastInfo,
	notifyToastSuccess,
	notifyToastWarning
} from '~/services';

import { AxiosApiError } from '../api';

const defaultSuccessMessage: Message = {
	title: 'common.feedback.success.title',
	text: 'common.feedback.success.text'
};

const defaultErrorMessage: Message = {
	title: 'common.feedback.error.title',
	text: 'common.feedback.error.text'
};

export function useToasts(): {
	axiosError: (err: AxiosApiError) => void;
	error: (msg?: string | Message) => void;
	info: (msg: string | Message) => void;
	success: (msg?: string | Message) => void;
	warning: (msg: string | Message, duration?: number) => void;
} {
	const { t } = useI18n();

	const translateMessage = (msg: Message) => ({ title: t(msg.title), text: t(msg.text) });
	const error = (msg?: string | Message) => notifyToastError(msg ?? translateMessage(defaultErrorMessage));
	const axiosError = (err: AxiosApiError) => {
		// prevent error toast when user is disconnected
		if (err?.response?.status !== 401) {
			error({ title: t('common.feedback.error.title'), text: err?.response?.data?.error });
		}
	};
	const success = (msg?: string | Message) => notifyToastSuccess(msg ?? translateMessage(defaultSuccessMessage));

	return {
		axiosError,
		error,
		info: notifyToastInfo,
		success,
		warning: notifyToastWarning
	};
}

export function useDialogs(): {
	error: (msg?: string | Message) => void;
	info: (msg: string | Message) => void;
	success: (msg?: string | Message) => void;
	warning: (msg: string | Message) => void;
} {
	const { t } = useI18n();

	const translateMessage = (msg: Message) => ({ title: t(msg.title), text: msg.text != undefined ? t(msg.text) : undefined });
	const error = (msg?: string | Message) => notifyDialogError(msg ?? translateMessage(defaultErrorMessage));
	const success = (msg?: string | Message) => notifyDialogSuccess(msg ?? translateMessage(defaultSuccessMessage));

	return {
		error,
		info: notifyDialogInfo,
		success,
		warning: notifyDialogWarning
	};
}
