import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';

export const SignInRoute: RouteRecordRaw = {
	name: RouteKeys.SIGN_IN,
	path: '/sign-in/:emailAsBase64?',
	props: route => ({ emailAsBase64: route.params.emailAsBase64, redirect: route.query.redirect }),
	component: () => import('./sign-in.vue'),
	meta: {
		public: true
	}
};

const enableDenario = import.meta.env.MS_DENARIO;
export const SignInDenarioRoute: RouteRecordRaw = {
	name: RouteKeys.SIGN_IN_DENARIO,
	path: '/sign-in-denario',
	props: () => ({ isDenario: true }),
	component: () => import('./sign-in.vue'),
	meta: {
		public: true,
		isForbidden: () => {
			return computed(() => !enableDenario);
		}
	}
};

export const ImpersonationRoute: RouteRecordRaw = {
	name: RouteKeys.IMPERSONATION,
	path: '/impersonation',
	component: () => import('./impersonation.vue'),
	meta: {
		public: true
	}
};

export const ConnectionRoute: RouteRecordRaw = {
	name: RouteKeys.CONNECTION,
	path: '/connection',
	component: () => import('./connection.vue'),
	meta: {
		public: true
	}
};
