import { RouteRecordRaw } from 'vue-router';

import { RouteKeys } from '~/router/routes.domain';

export const UserSignRoute: RouteRecordRaw = {
	name: RouteKeys.USER_SIGN,
	path: '/sign/:token',
	components: {
		default: () => import('./user-sign.vue'),
		appbar: () => import('../layout/app-bar/public-app-bar.vue')
	},
	props: {
		default: route => ({ token: route.params.token })
	},
	meta: {
		public: true,
		requiresAuth: false
	}
};
