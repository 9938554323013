import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useKonamiCode } from '@silae/composables';
import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

export const AdminLeaveDaysRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_LEAVE_DAYS,
	path: '/admin-leave-days',
	components: {
		default: () => import('~/pages/leave-days/leave-days.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { konami } = useKonamiCode();
			return computed(() => konami.value || Devices().isMobile || !useFeatures().hasLeaveDayAdminFullFeature.value);
		},
		isNavEntry: true,
		label: 'leave_days.navigation',
		icon: '$list',
		requiresAuth: true,
		order: 3
	}
};

export const AdminLeaveDaysRequestsRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_LEAVE_DAYS_REQUESTS,
	path: '/admin-requests/leave-days',
	component: () => import('~/pages/leave-days/leave-days.vue'),
	meta: {
		isForbidden: () => {
			const { konami } = useKonamiCode();
			return computed(() => !konami.value || Devices().isMobile || !useFeatures().hasLeaveDayAdminFullFeature.value);
		}
	}
};
