import { ListEntry } from '@silae/helpers';

export enum GroupType {
	COMPANY = 'COMPANY',
	GROUP = 'GROUP',
	ME = 'ME',
	NAMED_TEAM = 'NAMED_TEAM',
	TEAM = 'TEAM',
	TEAM_AND_SUB_TEAM = 'TEAM_AND_SUB_TEAM'
}

export enum GroupTypeIdByGroupType {
	ME = 1,
	TEAM = 10,
	TEAM_AND_SUB_TEAM = 11,
	NAMED_TEAM = 20,
	COMPANY = 30,
	GROUP = 40
}

export const GROUP_TYPE_PER_ID: Record<number, ListEntry<GroupType>> = {
	[GroupTypeIdByGroupType.ME]: {
		label: 'common.group_type.ME',
		value: GroupType.ME
	},
	[GroupTypeIdByGroupType.TEAM]: {
		label: 'common.group_type.TEAM',
		value: GroupType.TEAM
	},
	[GroupTypeIdByGroupType.TEAM_AND_SUB_TEAM]: {
		label: 'common.group_type.TEAM_AND_SUB_TEAM',
		value: GroupType.TEAM_AND_SUB_TEAM
	},
	[GroupTypeIdByGroupType.NAMED_TEAM]: {
		label: 'common.group_type.NAMED_TEAM',
		value: GroupType.NAMED_TEAM
	},
	[GroupTypeIdByGroupType.COMPANY]: {
		label: 'common.group_type.COMPANY',
		value: GroupType.COMPANY
	},
	[GroupTypeIdByGroupType.GROUP]: {
		label: 'common.group_type.GROUP',
		value: GroupType.GROUP
	}
};

export enum WeekDay {
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAY = 'FRIDAY',
	SATURDAY = 'SATURDAY',
	SUNDAY = 'SUNDAY'
}

export function weekdayAsValue(weekday: WeekDay): number {
	switch (weekday) {
		case WeekDay.MONDAY:
			return 1;
		case WeekDay.TUESDAY:
			return 2;
		case WeekDay.WEDNESDAY:
			return 3;
		case WeekDay.THURSDAY:
			return 4;
		case WeekDay.FRIDAY:
			return 5;
		case WeekDay.SATURDAY:
			return 6;
		case WeekDay.SUNDAY:
			return 7;
	}
}
