import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useKonamiCode } from '@silae/composables';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { defaultLayoutComponents } from '../layout/layout';

export const LeaveDaysRequestsRoute: RouteRecordRaw = {
	name: RouteKeys.LEAVE_DAYS_REQUESTS,
	path: '/requests/leave-days',
	components: {
		default: () => import('./leave-days.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			const { konami } = useKonamiCode();
			return computed(() => isAdmin.value || !konami.value);
		}
	}
};

export const LeaveDaysRoute: RouteRecordRaw = {
	name: RouteKeys.LEAVE_DAYS,
	path: '/leave-days',
	components: {
		default: () => import('./leave-days.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			const { konami } = useKonamiCode();
			return computed(() => isAdmin.value || konami.value);
		},
		label: 'leave_days.navigation',
		icon: '$list',
		isNavEntry: true,
		order: 3,
		requiresAuth: true
	}
};
