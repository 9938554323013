import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable } from 'rxjs';

import { useHttpCache } from '@silae/composables';
import {
	EmployeeOffboardingDTO,
	EndContractReasonDTO,
	fetchAdministeredOffboardingEmployees$,
	fetchEmployeeEndContractReasons$
} from '~/api';

import { Clearable } from '../store.domain';

export type AdministeredOffboardingEmployeesStore = Clearable & {
	fetchAdministeredOffboardingEmployees$: (companyId: number, invalidateCache?: boolean) => Observable<Array<EmployeeOffboardingDTO>>;
	fetchEmployeeEndContractReasons$: (
		companyId: number,
		employeeId: number,
		contractId: number
	) => Observable<Array<EndContractReasonDTO>>;
};

export const useAdministeredOffboardingEmployeesStore = defineStore<
	'administered-offboarding-employee-store',
	AdministeredOffboardingEmployeesStore
>('administered-offboarding-employee-store', () => {
	// Employees offboarding
	const { cache$: cacheAdministeredOffboardingEmployees$, clearCache: clearAdministeredOffboardingEmployees } = useHttpCache<
		number,
		Array<EmployeeOffboardingDTO>
	>();

	const clear = () => {
		clearAdministeredOffboardingEmployees();
	};

	const fetchEmployees$ = (companyId: number, invalidateCache?: boolean): Observable<Array<EmployeeOffboardingDTO>> => {
		if (invalidateCache) clearAdministeredOffboardingEmployees();

		return cacheAdministeredOffboardingEmployees$(companyId, fetchAdministeredOffboardingEmployees$(companyId));
	};

	const _fetchEmployeeEndContractReasons$ = (
		companyId: number,
		employeeId: number,
		contractId: number
	): Observable<Array<EndContractReasonDTO>> => fetchEmployeeEndContractReasons$(companyId, employeeId, contractId);

	return {
		clear,
		fetchAdministeredOffboardingEmployees$: fetchEmployees$,
		fetchEmployeeEndContractReasons$: _fetchEmployeeEndContractReasons$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAdministeredOffboardingEmployeesStore, import.meta.hot));
