import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { Optional } from '@silae/helpers';
import {
	AuthenticateRequestDTO,
	B2CIdTokenDTO,
	B2CTokenDTO,
	DenarioAuthenticateRequestDTO,
	UserDTO,
	applyJitMigration$,
	fetchPrincipal$,
	fetchTokenHint$,
	impersonateB2C$,
	parseJwt,
	signInB2C$,
	signInToBackend$,
	signInToDenarioBackend$,
	signOutFromB2C$,
	signOutFromBackend$
} from '~/api';
import { PrettyUser } from '~/domain';
import { clearSentryUserContext, initSentryUserContext } from '~/plugins';
import { Clearable } from '~/stores/store.domain';
import { prettyEmployeeName } from '~/utils';

function isImpersonatorToken(auth: B2CTokenDTO) {
	const token = parseJwt(auth.id_token) as B2CIdTokenDTO;
	return token.impersonator;
}

export interface IsImpersonator {
	hasImpersonateRight: boolean;
}

interface Impersonator {
	login: string;
	password: string;
	access_token: string;
	token_type: string;
	expires_in: string;
	refresh_token: string;
	id_token: string;
}

export enum ImpersonationModeOptions {
	SELF = 'SELF',
	OTHER = 'OTHER'
}

export type AuthenticationStore = Clearable & {
	authenticateUsingDenario$: (request: DenarioAuthenticateRequestDTO) => Observable<UserDTO | IsImpersonator>;
	authenticateUsingHeadlessB2C$: (request: AuthenticateRequestDTO) => Observable<UserDTO | IsImpersonator>;
	impersonateUsingHeadlessB2C$: (login: string) => Observable<Optional<UserDTO>>;
	continueB2CAuth: () => Observable<Optional<UserDTO>>;
	continueB2CJwt: (jwt: string) => Observable<UserDTO>;
	getStorageKey: (suffix: string) => Optional<string>;
	isAuthenticated: ComputedRef<boolean>;
	isImpersonator: ComputedRef<boolean>;
	principal: ComputedRef<Optional<PrettyUser>>;
	refreshPrincipal$: () => Observable<UserDTO>;
	signOut$: () => Observable<void>;
};

export const useAuthenticationStore = defineStore<'authentication-store', AuthenticationStore>('authentication-store', () => {
	const _principal: Ref<Optional<PrettyUser>> = ref(undefined);
	const _impersonator: Ref<Optional<Impersonator>> = ref(undefined);
	const _storageKey = computed(() => (_principal.value != null ? btoa(_principal.value?.login) : undefined));
	const isAuthenticated = computed(() => _principal.value != null);
	const isImpersonator = computed(() => _impersonator.value != null);

	const clear = () => {
		_principal.value = undefined;
		clearSentryUserContext();
	};

	const setPrincipal = (principal: UserDTO) => {
		const fullname = prettyEmployeeName(principal.firstname, principal.lastname);
		_principal.value = { ...principal, fullname };
		initSentryUserContext(principal.login, fullname);
	};

	const refreshPrincipal$ = () => fetchPrincipal$().pipe(tap(setPrincipal));

	const authUsingB2CToken = (b2cIdToken: string) => signInToBackend$(b2cIdToken).pipe(tap(principal => setPrincipal(principal)));

	// get token hint from backend and use it to get B2C state using a redirect as B2C forbid to use an HTTP call
	const authenticateUsingHeadlessB2C$ = (request: AuthenticateRequestDTO) =>
		fetchTokenHint$(request).pipe(
			switchMap(it => applyJitMigration$(it.jwt)),
			switchMap(() => signInB2C$(request)),
			switchMap((auth: B2CTokenDTO) => {
				if (isImpersonatorToken(auth)) {
					_impersonator.value = { ...request, ...auth };
					return of({ hasImpersonateRight: true } as IsImpersonator);
				} else {
					return authUsingB2CToken(auth.id_token);
				}
			})
		);

	const impersonateUsingHeadlessB2C$ = (impersonate: string) =>
		_impersonator.value != null
			? impersonateB2C$({
					impersonate: impersonate,
					login: _impersonator.value?.login,
					password: _impersonator.value?.password
				}).pipe(switchMap((auth: B2CTokenDTO) => authUsingB2CToken(auth.id_token)))
			: of(undefined);

	const continueB2CAuth = () => (_impersonator.value != null ? authUsingB2CToken(_impersonator.value.id_token) : of(undefined));
	const continueB2CJwt = (jwt: string) => authUsingB2CToken(jwt);

	const signOut$ = () =>
		signOutFromBackend$().pipe(
			switchMap(() => {
				_principal.value = undefined;
				return signOutFromB2C$();
			})
		);

	const getStorageKey = (suffix: string) => {
		if (!isAuthenticated.value) {
			return;
		}

		return `${_storageKey.value}-${suffix}`;
	};

	const authenticateUsingDenario$ = (auth: DenarioAuthenticateRequestDTO) => signInToDenarioBackend$(auth);

	return {
		clear,
		authenticateUsingHeadlessB2C$,
		impersonateUsingHeadlessB2C$,
		continueB2CAuth,
		continueB2CJwt,
		getStorageKey,
		isAuthenticated,
		isImpersonator,
		principal: computed(() => _principal.value),
		refreshPrincipal$,
		signOut$,
		authenticateUsingDenario$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAuthenticationStore, import.meta.hot));
