import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { $fa, Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

export const SignDocumentsRoute: RouteRecordRaw = {
	name: RouteKeys.SIGN_DOCUMENTS,
	path: '/admin-sign-documents',
	components: {
		default: () => import('../sign-document/sign-documents.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			return computed(() => Devices().isMobile || !useFeatures().hasSignFeature.value);
		},
		requiresAuth: true,
		requiresAdmin: true,
		isNavEntry: true,
		label: 'sign_documents.menu_item',
		icon: $fa('sign'),
		order: 4
	}
};
