import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useKonamiCode } from '@silae/composables';
import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { $fa, Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain.ts';

import { AdminLeaveDaysRequestsRoute } from '../leave-days/admin-leave-days.route.ts';
import { AdminRemoteWorkRoute } from '../mobilities/admin-remote-work.ts';

export const AdminRequestsRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_REQUESTS,
	path: '/admin-requests',
	components: {
		default: () => import('~/pages/requests/requests.vue'),
		...defaultLayoutComponents
	},
	children: [AdminLeaveDaysRequestsRoute, AdminRemoteWorkRoute],
	redirect: () => AdminLeaveDaysRequestsRoute,
	meta: {
		isForbidden: () => {
			const { konami } = useKonamiCode();
			return computed(() => !konami.value || Devices().isMobile || !useFeatures().hasLeaveDayAdminFullFeature.value);
		},
		label: 'requests.navigation',
		icon: $fa('list-bullets'),
		isNavEntry: true,
		order: 3,
		requiresAuth: true,
		requiresAdmin: true
	}
};
