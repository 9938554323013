import { RouteRecordRaw } from 'vue-router';

import { GedRoute } from '~/pages/GED/ged.route';
import { AccountRoute } from '~/pages/account/account.route';
import { AdminAgendaRoute } from '~/pages/admin/agenda/admin-agenda.route';
import { ConfigurationRoute } from '~/pages/admin/configuration/configuration.route';
import { AdminDocumentsRoute } from '~/pages/admin/documents/admin-documents.route';
import { EmployeeDetailsRoute } from '~/pages/admin/employees/employee-details/employee-details.route';
import { AdminEmployeeManagementRoute } from '~/pages/admin/employees/employees.route';
import { AdminLeaveDaysRoute } from '~/pages/admin/leave-days/admin-leave-days.route.ts';
import { MedicalCheckupsRoute } from '~/pages/admin/medical-checkups/medical-checkups.route';
import { AdminRequestsRoute } from '~/pages/admin/requests/admin-requests.route.ts';
import { SignDocumentsRoute } from '~/pages/admin/sign-document/sign-documents.route';
import { AgendaRoute } from '~/pages/agenda/agenda.route';
import { AutomaticEarnedWageAccessesRoute } from '~/pages/automatic-earned-wage-accesses/automatic-earned-wage-accesses.route';
import { HomeRoute } from '~/pages/home/home.route';
import { LeaveDaysRoute } from '~/pages/leave-days/leave-days.route.ts';
import { MeetingsRoute } from '~/pages/meetings/meetings.route';
import { RequestsRoute } from '~/pages/requests/requests.route.ts';
import { DownloadFileRoute, PublicDownloadExpiredRoute } from '~/pages/share-document/download-file.route';
import { UserSignRoute } from '~/pages/sign-document-public-page/user-sign.route';
import { ConnectionRoute, ImpersonationRoute, SignInDenarioRoute, SignInRoute } from '~/pages/sign-in/sign-in.route';
import { UpdatePasswordRoute } from '~/pages/update-password/update-password.route';
import { RouteKeys } from '~/router/routes.domain';

import { PayrollRoute } from '../pages/admin/payroll/payroll.route';

const FallbackRoute: RouteRecordRaw = {
	name: RouteKeys.NOT_FOUND,
	path: '/:pathMatch(.*)*',
	redirect: HomeRoute,
	meta: {
		public: true
	}
};

export const routes = [
	AccountRoute,
	AdminAgendaRoute,
	AdminDocumentsRoute,
	AdminEmployeeManagementRoute,
	AdminLeaveDaysRoute,
	AdminRequestsRoute,
	AgendaRoute,
	MedicalCheckupsRoute,
	DownloadFileRoute,
	PublicDownloadExpiredRoute,
	AutomaticEarnedWageAccessesRoute,
	ConfigurationRoute,
	ConnectionRoute,
	EmployeeDetailsRoute,
	GedRoute,
	HomeRoute,
	ImpersonationRoute,
	LeaveDaysRoute,
	RequestsRoute,
	MeetingsRoute,
	PayrollRoute,
	SignInRoute,
	SignInDenarioRoute,
	UpdatePasswordRoute,
	UserSignRoute,
	SignDocumentsRoute,
	FallbackRoute
];
