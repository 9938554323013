import { Optional } from '@silae/helpers';
import { EmployeeDTO, GroupType, GroupTypeIdByGroupType, UserCompanyDTO } from '~/api';
import { Employee, Member } from '~/domain';
import { getStorageKey } from '~/utils/storage.utils';

export const PREFERRED_NAME_ORDER_LOCAL_STORAGE_KEY = getStorageKey('preferred-nameOrder');
export const EMPLOYEE_DETAILS_NO_VALUE_PLACEHOLDER = '-';

/**
 * Converts a DTO for an employee to an employee domain object
 * @param employeeDTO
 * @param companyId
 */
export function employeeDtoToEmployee<Source extends EmployeeDTO>(employeeDTO: Source, companyId?: number): Employee<Source> {
	return {
		companyId,
		...employeeDTO,
		fullName: prettyEmployeeName(employeeDTO.firstName, employeeDTO.lastName)
	};
}

export function prettyEmployeeName(firstName: Optional<string>, lastName: Optional<string>): string {
	let fullName = '';

	// clean lastName
	if (lastName != null && lastName.trim() !== '') {
		lastName = `${lastName.toUpperCase().trim()}`;
	} else {
		lastName = '';
	}

	// clean firstName
	if (firstName != null && firstName.trim() !== '') {
		firstName = `${firstName
			.toLowerCase()
			.replace(/(^|[\s-])[A-Za-zÀ-ÖØ-öø-ÿ]/g, c => c.toUpperCase())
			.trim()}`;
	} else {
		firstName = '';
	}

	// temporary: we use direct access to localStorage
	const nameOrder = localStorage.getItem(PREFERRED_NAME_ORDER_LOCAL_STORAGE_KEY);

	// show name depending on the display order selected in the User profile
	if (nameOrder == 'firstname-lastname') {
		fullName = `${firstName} ${lastName}`.trim();
	} else {
		fullName = `${lastName} ${firstName}`.trim();
	}

	return fullName;
}

export function employeeToMember(employee: Employee, company: UserCompanyDTO, groupType: GroupType): Member {
	return {
		companyId: company.companyId,
		companyName: company.companyName,
		employeeId: employee.employeeId,
		groupId: GroupTypeIdByGroupType[groupType],
		isPrincipal: false,
		fullName: employee.fullName,
		firstName: employee.firstName,
		lastName: employee.lastName,
		registrationNumber: employee.registrationNumber
	};
}
